import { render, staticRenderFns } from "./subject.vue?vue&type=template&id=c9386b82&scoped=true&"
import script from "./subject.vue?vue&type=script&lang=js&"
export * from "./subject.vue?vue&type=script&lang=js&"
import style0 from "./subject.vue?vue&type=style&index=0&id=c9386b82&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9386b82",
  null
  
)

export default component.exports